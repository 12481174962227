.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.map-legend {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0.2rem;
  color: rgba(255, 255, 255, 0.6);
  font-family: Montserrat, sans-serif;
}

.map-legend-gradient {
  background-image: linear-gradient(to left,
      #82FFA2,
      #96FF7F,
      #FDFF73,
      #FFD96D,
      #FFA26C,
      #FF5663,
      #C9424C,
      #962F37,
      #962F37,
      #651C22);
  height: 25%;
  align-self: center;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-ctrl-top-right {
  display: none;
}