.step2Email {
    width: 600px;
    height: 200px;
    color: rgba(255, 255, 255, 0.87);
    background: #181818;
    border: 1px solid rgba(255, 255, 255, 0.38)
}

.step2Email:hover {
    border: 1px solid rgba(255, 255, 255, 0.6)
}

.react-multi-email.focused {
    border: 1px solid rgba(255, 255, 255, 0.87);
    background: #181818;
}

.step2EmailInput {
    color: rgba(255, 255, 255, 0.87);
    background: #181818;
}

.react-multi-email.empty > span[data-placeholder] {
    color: rgba(255, 255, 255, 0.6)
}
