.sidenav {
    position: sticky;
    width: 100%;
    grid-row-gap: 20px;
    grid-template-rows: 6rem 5rem 5rem 5rem 5rem auto 3rem 3rem;
    padding-bottom: 2rem;
    background: #212121;
}

.sidenav-link>img {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 5px;
    align-items: center;
    margin: auto;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.45rem;
    opacity: 0.87;
    cursor: pointer;
}

.sidenav-link>p {
    color: rgba(255, 255, 255, 0.87);
    font-weight: 550;
    font-size: 10pt;
}

.sidenav-link>img:hover:not(.sidenav-link-disabled>img, .sidenav-link-selected>img) {
    background-color: rgba(255, 255, 255, 0.08)
}

.sidenav-link-selected>img {
    background-color: rgba(255, 255, 255, 0.24)
}

.sidenav-link-disabled>img {
    opacity: 0.38;
    cursor: default;
}

.sidenav-link-disabled>p {
    color: rgba(255, 255, 255, 0.38);
    font-weight: 550;
    font-size: 10pt;
}