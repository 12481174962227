.connect-modal-overlay {
    top: 10vh;
    left: 8rem;
}

.connect-modal {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #181818 !important;
    margin-top: 20rem;
    margin-left: 12rem;
}