.connection-connected {
    width: 100%;
    height: 5px;
    background-color: rgba(132, 252, 136, 0.87);
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
}

.connection-not-connected {
    width: 100%;
    height: 5px;
    background-color: rgba(251, 125, 117, 0.87);
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
}

.connecting-progress {
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
}