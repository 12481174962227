.dashboard-selector {
  height: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  border-right: 1px solid rgba(255, 255, 255, 0.12);
  grid-template-columns: 42% auto;
}

.dashboard-selector-option {
  grid-gap: 0.5rem;
  padding: 0.5rem;
  color: rgba(255, 255, 255, 0.87);
  cursor: pointer;
  align-items: center;
  height: 2.5rem
}

.dashboard-selector-option-disabled {
  cursor: not-allowed;
  opacity: 0.38;
}

.dashboard-selector-option:hover&:not(.dashboard-selector-option-disabled) {
  background: rgba(255, 255, 255, 0.38);
}

.dashboard-selector-selected {
  background: rgba(255, 255, 255, 0.24);
  border-radius: 5px;
}

.tags-selector {
  height: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  border-right: 1px solid rgba(255, 255, 255, 0.12);
  grid-template-columns: 42% auto;
}

.tag-selected {
  background-color: rgba(255, 255, 255, 0.24) !important;
}

.tag {
  height: 1.5rem;
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.87);
  border-radius: 100px;
  cursor: pointer;
  font-size: 12pt;
  padding: 0 1rem;
}