.radio-button {
    border-radius: 4px;
    justify-content: space-evenly;
}

.radio-button-option {
    cursor: pointer;
    align-items: center;
    transition: .2s;
    border-right: 1px solid #181818;
    border-left: 1px solid #181818;
    color: rgba(255, 255, 255, 0.38);
}

.radio-button-option:hover:not(.radio-button-option-disabled, .radio-button-option:checked) {
    background: rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.87);
}

.radio-button-option:checked {
    background: rgba(255, 255, 255, 0.24);
}

.radio-button-option-disabled {
    padding: 0.75rem;
    align-items: center;
    transition: .2s;
    border-right: 1px solid #181818;
    border-left: 1px solid #181818;
    color: rgba(255, 255, 255, 0.38);
    cursor: not-allowed;
}

.radio-button>label:first-child>div {
    border-radius: 4px 0 0 4px;
}

.radio-button>label:last-child>div {
    border-radius: 0 4px 4px 0;
}

.tooltip-modal {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #181818 !important;
    margin-top: 20rem;
    margin-left: 12rem;
}